import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './PrayerTime.css'; // Import your custom CSS file
import currentMonth from '../../assets/PrayerImage/currentMonth.jpg';
import nextMonth from '../../assets/PrayerImage/nextMonth.jpg';


function PrayerTime() {
    const [prayerTimes, setPrayerTimes] = useState(null);
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        fetchPrayerTimes();
        const today = new Date();
        const formattedDate = today.toLocaleDateString('nl-NL', {
            weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
        });
        setCurrentDate(formattedDate);
    }, []);

    const fetchPrayerTimes = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/PrayerTimes/PrayerTime');
            setPrayerTimes(response.data);
        } catch (error) {
            console.error('Error fetching prayer times:', error);
        }
    };

    return (
        <Container className="mt-5 prayer-times-container">
            <Row>
                <Col>
                    <h1 className="prayer-times-title">Gebedstijden Noordwijk</h1>
                    <h4>{currentDate}</h4>
                    <br />
                    <div className="prayer-images-container">
                        <img src={currentMonth} className="left-image" alt="Current Month" />
                        <img src={nextMonth} className="left-image" alt="Next Month" />
                    </div>

                </Col>
            </Row>
        </Container>
    );
}

export default PrayerTime;