import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberme, setRememberme] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "email") setEmail(value);
        if (name === "password") setPassword(value);
        if (name === "rememberme") setRememberme(e.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Please fill in all fields.");
            return;
        }

        setError("");

        try {
            // Change `email` to `username` if that's required by the backend
            const requestBody = { email: email, password, rememberme };
            const response = await axios.post(process.env.REACT_APP_API_URL + "/api/Login/signin", requestBody);

            if (response.status !== 200) {
                const errorData = response.data;
                setError(errorData.error || "Error logging in.");
                return;
            }

            const data = response.data;
            const token = data.token;

            // Save the token securely
            localStorage.setItem("authToken", token);

            setError("Successful login.");
            window.location.replace('/vrijdagpreken'); // Redirect to another page
        } catch (error) {
            console.error("Request failed:", error);
            setError("Error logging in. Please try again later.");
        }
    };

    return (
        <div className="container mt-5">
            <h3>Login</h3>
            <br />
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label" htmlFor="email">Email:</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="password">Password:</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-check mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberme"
                        name="rememberme"
                        checked={rememberme}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="rememberme">Remember Me</label>
                </div>
                <div className="mb-3">
                    <button type="submit" className="btn btn-primary rounded-pill">Login</button>
                </div>
            </form>
            {error && <p className="text-danger">{error}</p>}
        </div>
    );
}

export default Login;
