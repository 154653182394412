//import React, { useState, useEffect, createContext } from 'react';

//export const UserContext = createContext({});

//function AuthorizeView(props) {
//    const [authorized, setAuthorized] = useState(false);
//    const [loading, setLoading] = useState(true);
//    const [user, setUser] = useState({ email: "" });

//    useEffect(() => {
//        fetch(process.env.REACT_APP_API_URL + "/pingauth", {
//            method: "GET",
//            credentials: 'include',
//            headers: {
//                            'Content-Type': 'application/json',
//                        },

//        })
//                    .then(response => {
//                        if (response.status === 200) {
//                            return response.json();
//                        } else {
//                            throw new Error('Unauthorized or unexpected error');
//                        }
//                    })
//                    .then(data => {
//                        setUser({ email: data.email });
//                        setAuthorized(true);
//                    })
//                    .catch((error) => {
//                        console.log(error.message);
//                    })
//                    .finally(() => {
//                        setLoading(false);
//                    });
//    }, []);

//    if (loading) {
//        return <p>Loading...</p>;
//    }

//    if (!authorized) {
//        return null;
//    }

//    return (
//        <UserContext.Provider value={user}>
//            {props.children}
//        </UserContext.Provider>
//    );
//}

//export function AuthorizedUser(props) {
//    const user = React.useContext(UserContext);

//    if (props.value === "email") return <>{user.email}</>;
//    else return <></>;
//}

//export default AuthorizeView;

import React, { useState, useEffect, createContext } from 'react';

export const UserContext = createContext({});

function AuthorizeView(props) {
    const [authorized, setAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({ email: "" });

    useEffect(() => {
        console.log("Effect triggered");

        const token = localStorage.getItem("authToken");

        if (!token) {
            console.log("No token found");
            setAuthorized(false);
            setLoading(false);
            return;
        }

        fetch(process.env.REACT_APP_API_URL + "/api/Login/pingauth", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                console.log("Response status:", response.status);  // Log the response status
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error('Unauthorized or unexpected error');
                }
            })
            .then(data => {
                console.log("Data received:", data);
                setUser({ email: data.email });
                setAuthorized(true);
            })
            .catch((error) => {
                console.log("Error:", error.message);
                setAuthorized(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    if (loading) {
        return <p>Loading...</p>;
    }

    if (!authorized) {
        return null;
    }

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    );
}

export function AuthorizedUser(props) {
    const user = React.useContext(UserContext);

    if (props.value === "email") return <>{user.email}</>;
    else return <></>;
}

export default AuthorizeView;
