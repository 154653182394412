import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthorizeView from '../Authentication/AuthorizeView';
import './PageList.css'; // Create and import this CSS file for custom styles
import Login from '../../pages/login';

const PageList = () => {
    const [pages, setPages] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const fetchPages = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/FileProcessing/files'); // Update to your API URL
                if (response.data && Array.isArray(response.data.data)) {
                    setPages(response.data.data);
                } else {
                    console.error('API response is not an array:', response.data);
                }
            } catch (error) {
                console.error('Error fetching pages:', error);
            }
        };

        fetchPages();
    }, []);

    const handleLoginSuccess = () => {
        console.log("Login was successful!");
        setLoggedIn(true); // Set loggedIn to true after a successful login
    };

    const filteredPages = pages.filter(page =>
        page.fileName && page.fileName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedPages = [...filteredPages].sort((a, b) => {
        if (sortOrder === 'asc') {
            return new Date(a.fileDate) - new Date(b.fileDate);
        } else {
            return new Date(b.fileDate) - new Date(a.fileDate);
        }
    });

    const toggleSortOrder = () => {
        setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handlePreview = (page) => {
        navigate(`/preview/${page.fileName}`, { state: { content: page.content } });
    };

    return (
        <div className="container mt-5">
            <div className="text-center mb-4">
                <h1>Vrijdagpreken van Moskee Arrahman</h1>
                <h5>Hier vindt u de vertaling van de preek die de imam iedere vrijdag geeft.</h5>
            </div>
            <br />
            <br />
            <br />
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Zoek bij datum of titel"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <br />
            <br />
            <br />
           
            <AuthorizeView loggedIn={loggedIn}>
                <div className="text-center mt-3">
                    <Link to="/pagina/edit" className="btn btn-primary">Create New Page</Link>
                </div>
            </AuthorizeView>
            <br />
            <br />
            <br />
            <div className="text-end mb-3">
                <button className="btn btn-secondary" onClick={toggleSortOrder}>
                    Sorteer op datum {sortOrder === 'asc' ? 'Oplopend' : 'Aflopend'}
                </button>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Link naar het document</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedPages.map((page, index) => {
                        const [date, ...titleParts] = page.fileName.replace('.md', '').split(' ');
                        const title = titleParts.join(' ');
                        return (
                            <tr key={index} onClick={() => handlePreview(page)} style={{ cursor: 'pointer' }}>
                                <td>{date}</td>
                                <td>{title}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PageList;
