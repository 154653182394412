import { useState } from "react"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"

function LoginForm(){

    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [val, setVal] = useState("")
    const [rememberme, setRememberme] = useState("")


    async function handleLogin(e){
        e.preventDefault()
        try {
            const requestBody = {email, val, rememberme}
            const response = await axios.post(process.env.REACT_APP_API_URL + "/api/XMLFile/verification1", requestBody)
            localStorage.setItem('access_token', response.data.access_token)
            navigate('/')
        } catch (error) {
            console.log(error);
           
        }
    }

    return (
        <div className="container" style={{marginTop:"10vh"}}>
            <form onSubmit={handleLogin}>
                <h2>Login to your account</h2>
                <p>Welcome back!</p>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email address :</label>
                    <input onChange={e => {setEmail(e.target.value)}} type="email" className="form-control" id="email"/>
                </div>
                <div className="mb-3">
                    <label htmlFor="text" className="form-label">test :</label>
                    <input onChange={e => {setVal(e.target.value)}} type="text" className="form-control" id="val"/>
                </div>
                <div className="form-check mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberme"
                        name="rememberme"
                        checked={rememberme}
                        onChange={e => { setRememberme(e.target.value) }}
                    />
                    <label className="form-check-label" htmlFor="rememberme">Remember Me</label>
                </div>
                <button type="submit" className="btn btn-primary">LOG IN</button>
            </form>
        </div>
    )
}

export default LoginForm