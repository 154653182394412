import { useNavigate } from "react-router-dom";
import React from "react";

function LogoutLink({ children }) {
  

    const handleSubmit = (e) => {
        e.preventDefault();

        // Clear client-side authentication data
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("authToken");

        fetch(process.env.REACT_APP_API_URL + "/api/Login/logout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    // Redirect to the login page
                    window.location.href ="/";
                } else {
                    console.error("Logout failed.");
                }
            })
            .catch((error) => {
                console.error("An error occurred during logout:", error);
            });
    };

    return (
        <div className="container mt-5">
            <h3>Logout</h3>
            <br />
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <button type="submit" className="btn btn-primary rounded-pill">logout</button>
                </div>
            </form>
        </div>
       
    );
}

export default LogoutLink;
